var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-card',{staticClass:"mb-4 border-g-e8",attrs:{"elevation":"0"}},[_c('v-card-text',[[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"disabled":true}},[_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")])]}}])})],_c('v-list-item-group',[(!_vm.folders.length && !_vm.files.length)?[_vm._v(" "+_vm._s(_vm.$t('invoices.files_or_folders_not_available'))+" ")]:_vm._e(),(!_vm.currentFolder)?_vm._l((_vm.folders),function(folder){return _c('v-list-item',{key:folder.id,on:{"click":function($event){return _vm.setPath(folder)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("folder")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(folder.text)}})],1)],1)}):_vm._e(),(_vm.currentFolder)?[_c('v-list-item',{key:0,on:{"click":function($event){return _vm.load()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("folder")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("..")])],1)],1),_vm._l((_vm.files),function(file){return _c('v-list-item',{key:file.id,on:{"click":function($event){return _vm.downloadFile(
                                    file.id,
                                    file.file_name,
                                    file.extension
                                )}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("description")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                                        file.file_name +
                                        '.' +
                                        file.extension
                                    )}})],1),_c('v-list-item-action',[_c('div')])],1)})]:_vm._e()],2)],2)],1)],1),_c('overlay',{attrs:{"show":_vm.busy}},[[_vm._t("default")]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }