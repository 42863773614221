<template>
    <div>
        <page-title>{{ $t('invoices.page_title') }}</page-title>
        <company-invoices-list ref="table" :company-id="companyId">
        </company-invoices-list>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import CompanyInvoicesList from '@/components/pages/invoices/Company'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        PageTitle,
        CompanyInvoicesList,
    },
    mixins: [AuthMixin],
    props: {
        companyId: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            routeType,
            companyFilter: null,
        }
    },
}
</script>
